*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	font-size: 18px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: text;
}

body {
	margin: 0;
	font-family: 'DM Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.25rem;
	color: white;
	background-color: black;
}

.app {
	margin: 0;
	cursor: default;
	min-height: 100vh;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

a {
	color: inherit;
}

.main {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 3.75%;
	padding-right: 3.75%;
	max-width: 1240px;
	zoom: 1;
}

.header,
.footer {
	padding: 0 3rem;
}

.form {
	width: 100%;
	max-width: 25rem;
	position: relative;
	margin-bottom: 3rem;
	float: left;
}
.generated {
	width: 50%;
	margin-bottom: 3rem;
	margin-left: auto;
}
.main__title {
	font-size: 4em;
	font-weight: 1000;
	text-rendering: optimizeLegibility;
	line-height: 1.1;
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
}
.header__title,
.footer {
	font-weight: 400;
	font-size: 0.65rem;
	line-height: 1.5;
}
.header__title,
.footer__note {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

@media only screen and (max-width: 768px) {
	html {
		font-size: 14px;
	}
	.main__title {
		font-size: 3em;
	}
	.form {
		float: none;
	}
	.generated {
		margin-bottom: 3rem;
		margin-left: 0;
		width: 100%;
	}
	.header,
	.footer {
		padding: 0 3.75%;
	}
}

.generated p {
	font-size: 1em;
}

button.ant-btn {
	display: flex;
	align-items: center;
}

svg {
	margin-left: 10px;
}

.copy {
	display: flex;
	align-items: center;
	margin-top: 2rem;
}

.copied {
	margin-left: 15px;
	font-size: 0.8rem;
	color: yellowgreen;
}

.footer__note {
	text-align: right;
}

.header__title {
	text-transform: uppercase;
	letter-spacing: 2px;
}
